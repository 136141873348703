// @font-face {
//     font-family: 'AvantGarde Md BT', sans-serif;
//     src:  url("http://fonts.cdnfonts.com/css/avantgarde-md-bt");
// }
@font-face {
    font-family: 'AvantGarde Md BT';
    font-style: normal;
    font-weight: 400;
    src: local('AvantGarde Md BT'), url('../assets/fonts/AVGARDM_2.woff') format('woff');
}
$font-family_1: 'AvantGarde Md BT', sans-serif;