.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ais-SearchBox-form > .ais-SearchBox-input {
  appearance: none;
  padding: .3rem 1.7rem;    
  position: relative;
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 5px;
  outline: none;
  width:35rem;   
  justify-content: center;
  margin:auto;
  display: none;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.ais-Highlight-highlighted {
font-weight: bold;
font-style: normal;
}
.instantSearch .ais-SearchBox-submit { 
display: none !important;
}
.instantSearch .ais-SearchBox-reset { 
display: none !important;
}
.searchIconButton svg{
background-repeat: none;
}
/* .headerMenu.selected{
@apply border-b-2 border-orange
}
.nav-item{
 @apply text-dark_gray text-[0.95rem] font-bold hover:border-b-2 border-orange 
}
.date-text{
    @apply text-light_gray-400 font-normal text-[0.85rem] leading-5 inline
}
.card-title{
  @apply text-dark_gray font-semibold text-xl
}
.card-decs{
  @apply text-dark_gray font-normal text-[0.9rem]
}
.sub-title{
  @apply text-blue text-2xl font-bold text-center  mt-10
}
.no-result{
  @apply text-light_gray-400 text-xl font-normal text-center pb-[19.25rem] mt-4
}  */
.inner-content::-webkit-scrollbar {
  width: 2px;
  height:2px;
  margin-left: 5px;
  padding-left: 5px;
}  
.inner-content::-webkit-scrollbar-track {
  background: white;
  border-radius: 2px;
 
}
.inner-content::-webkit-scrollbar-thumb {
  background-color: gainsboro;
  border-radius: 4px;   
  border: 2px solid gainsboro;   
}
.instantSearch .ais-Pagination{
  color: #3a4570;
}
.instantSearch .ais-Pagination-list {
    justify-content: right;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0;
    list-style: none;
}
.instantSearch .ais-Pagination-item+.ais-Pagination-item {
    margin-left: .3rem;
}
.instantSearch .ais-Pagination-item--selected .ais-Pagination-link {
    color: #fff;
    background-color: rgb(246,78, 0);
    border-color: rgb(246,78, 0);
}
.instantSearch .ais-Pagination-link {
    padding: .3rem .6rem;
    display: block;
    border: 1px solid #c4c8d8;
    border-radius: 5px;
    transition: background-color .2s ease-out;
}
.instantSearch .ais-SearchBox-loadingIndicator {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 40px;
  top: 50%;
  right: .3rem;
  justify-content: center;
  transform: translateY(-50%);
}

.nav-text{
  border-bottom: 3px solid  #74C974;
  font-size: 16px;
  font-weight: 600;
  color: black;
  background-color: white;
  display: inline-block;

}

.nav-text:hover{
  color: black;
}

.nav-text1:hover{
  color: black;
}

.nav-text1{
  
  font-size: 16px;
  font-weight: 600;
  color: black;
  background-color: white;
  display: inline-block;
}

/* .border-b-2 border-orange inline-block  text-[1rem] font-semibold text-black bg-white py-1 */

.tag-text{
  margin-right: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  
}


/* mx-5 text-decoration-none col list-unstyled text-[1rem] text-black font-medium leading-[1.125rem]   md:mx-15  d-flex space-x-8 md:space-x-4 lg:mx-16" style={{font: '1rem'}} */