$color_1: #666666;
$color_2: inherit;
$color_3: #74c947;
$color_4: #333333;
$color_5: #17525f;
$color_6: #74c974;
$color_7: #74c937;
$font-family_1: "AvantGarde Md BT", sans-serif;
$background-color_1: #17525f;
$background-color_2: #877551;
$background-color_3: #4c835b;
$background-color_4: #6778b3;
$background-color_5: transparent;
$background-color_6: #3cfa75;
$background-color_7: #e7e3e3;
$background-color_8: #74c937;
$background-color_9: #eeeeee;

:root {
  --content-fs: 1.2em;
  --heading-fs: 3.4em;
  --subheading-fs: 2.5em;
  font-weight: 700;
}

body {
  font-size: 1rem;
  font-family: $font-family_1;
  font-weight: 500;
  color: $color_1;
}

a {
  &:hover {
    color: $color_2;
  }
}

.gif-image {
  width: 40vw;
}

.margin-top {
  margin-top: 150px;
}

.pill {
  border: 5px solid #74c947;
}

.rectangle {
  background-color: $background-color_1;
  height: 585px;
  width: 255px;
}

.rectangle1 {
  background-color: $background-color_2;
  height: 585px;
  width: 255px;
}

.rectangle2 {
  background-color: $background-color_3;
  height: 585px;
  width: 255px;
}

.rectangle3 {
  background-color: $background-color_4;
  height: 585px;
  width: 255px;
}

li {
  p {
    color: $color_1;
  }

  color: $color_3;
}

.number-text {
  line-height: 55px;
}

.line {
  border-right: 1px solid rgba(23, 82, 95, 0.5);
}

.link-color {
  &:hover {
    text-decoration: underline !important;
  }

  color: $color_3 !important;
  text-decoration: none;
}

.loan-bg {
  background: rgba(242, 242, 242, 0.7);
  background: rgba(242, 242, 242, 0.7);
}

.navbar {
  .navbar-nav {
    .nav-link {
      &:hover {
        color: $color_3 !important;
      }
    }
  }
}

.opacity {
  opacity: 0.4;
}

.dropdown {
  .dropdown-menu {
    .dropdown-item {
      background-color: $background-color_5;
      font-weight: 600;
      font-size: 15px;
      color: $color_4;

      &:hover {
        color: $color_3;
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    .hover {
      background-color: $background-color_5;
      font-weight: 600;
      font-size: 18px;
      color: $color_5;

      &:hover {
        background: linear-gradient(90deg,
            #e1e0e0 36.6%,
            rgba(217, 217, 217, 0) 100%) !important;
        color: $color_5;
      }
    }
  }
}

.dropdown {
  .dropdown-menu {
    .cus-center {
      background-color: $background-color_5;
      font-weight: 600;
      font-size: 18px;
      color: $color_5;

      &:hover {
        color: $color_3;
      }
    }
  }
}

#nav.card {
  &:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
    border: 2px solid #74c947;
  }
}

.sec_color {
  color: $color_5 !important;
}

.accordion-collapse {
  border: 0;
}

.accordion-button {
  &:focus {
    box-shadow: none;
    border: none;
  }

  &:not(.collapsed) {
    background: none;
    color: $color_3 !important;

    &::after {
      width: auto;
      height: auto;
      background-image: none !important;
      content: "-" !important;
      font-size: 48px;
      transform: translate(-5px, -4px);
      transform: rotate(0deg);
    }
  }

  &::after {
    width: auto;
    height: auto;
    content: "+" !important;
    font-size: 30px;
    background-image: none !important;
    font-weight: 400;
    color: $color_3;
    transform: translateY(-4px);
  }
}

.cardTitle {
  font-size: 1.5em;
  color: $color_1;
  font-weight: 700;
}

.card-icon {
  position: absolute;
  top: 50px;
  left: 56px;
}

.cardText {
  font-size: 1.2em;
  font-weight: 400;
  line-height: 25;
}

@media only screen and (min-width: 992px) {
  .navbar {
    padding: 0;

    .navbar-brand {
      padding: 0 0.7em;
    }

    .navbar-nav {
      .nav-link {
        padding: 1em 0;
      }

      .nav-item {
        margin: 0 0.75em;
        position: relative;

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
          background-color: $background-color_6;
          width: 0%;
          content: "";
          height: 2px;
          transition: all 0.3s;
        }
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 991px) {
  .dropdown-Avg-img {
    margin-left: 18px;
    margin-top: -7px;
  }

  .dropdown-Inc-img {
    margin-left: 26px !important;
    margin-top: -1rem !important;
    max-width: 148px !important;
  }

  .dropdown-profit-img {
    margin-left: 30px;
  }

  .dropdown-doctor-img {
    margin-left: calc(100% - 148px);
    height: calc(100% - 25px);
  }
}

@media (min-width: 578px) and (max-width: 990px) {

  #nav.card {
    width: 22.5vw;
    height: 200px;
    border-radius: 15px;
  }

  #nav.card-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 25px;
    color: $color_5;
  }

  #nav.card-text {
    font-size: 12px;
    color: $color_6;
  }

  .dropdown-Inc-img {
    margin-top: -11px;
    max-width: 148px;
  }

  .dropdown-doctor-img {
    margin-left: calc(100% - 128px);
    height: calc(100% - 25px);
    margin-top: -10px;
  }

  .Rev-Loan {
    font-size: 12px !important;
    margin-top: -0.5rem !important;
  }
}

@media (min-width: 900px) and (max-width: 990px) {
  /* .dropdown-doctor-img{
		width: 303px !important;
		margin-left: -117px !important;
		margin-top: -18.5px !important;
		} */
}

@media (min-width: 768px) and (max-width: 990px) {
  .line-md {
    border-right: 1px solid rgba(23, 82, 95, 0.5);
  }

  .border-right {
    border-right: 1px solid rgba(23, 82, 95, 0.5);
  }
  
  .border-left {
    border-left: 1px solid #9fed8c !important;
  }
  
  .loanAmount {
    font-size: 28px !important;
    font-weight: 400;
  }

  .fw-400 {
    font-size: 16.2px !important;
  }
}

@media (min-width: 990px) and (max-width: 1120px) {
  .nav-item {
    margin: 0 0.2em !important;
    position: relative;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-color: $background-color_6;
      width: 0%;
      content: "";
      height: 2px;
      transition: all 0.3s;
    }
  }


}

@media (min-width: 991px) and (max-width: 1199px) {
  #nav.card {
    width: 23vw;
    height: 145px;
    border-radius: 15px;
  }

  .navbar {
    .navbar-nav {
      .nav-item {
        //margin: 0 0.5em;
      }
    }
  }

  .nav-margin {
    margin-left: 20px;
  }

  .Rev-Loan {
    font-size: 12.5px !important;
    margin-top: -0.25rem !important;
  }

  .dropdown-Avg-img {
    margin-left: -58px;
    margin-top: 34px;
  }

  .dropdown-Inc-img {
    margin-left: -51px;
    margin-top: 29px;
    max-width: 138px;
  }

  .dropdown-profit-img {
    margin-left: -38px;
    margin-top: 32px;
  }

  .dropdown-doctor-img {
    /* margin-left:-117px;
		margin-top: 18px;
		width:209px; */
    margin-left: -2px;
    margin-top: 30px;
    height: calc(100% - 54px);
  }

  .loanAmount {
    font-size: 25.9px !important;
    font-weight: 400;

  }

  #nav.card-title {
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: $color_5;
  }

  #nav.card-text {
    font-size: 12px;
    color: $color_6;
  }

  .leftmargin {
    margin-left: 100px;
  }

  .line-md {
    border-right: 1px solid rgba(23, 82, 95, 0.5);
  }
}

@media (min-width: 990px) {
  
.border-right {
  border-right: 1px solid rgba(23, 82, 95, 0.5);
}

.border-left {
  border-left: 1px solid #9fed8c !important;
}

  .underline {
    position: relative;
    text-decoration: none;

    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: $background-color_8;
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover {
      &::before {
        transform: scaleX(1);
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  #nav.card {
    width: 22vw;
    height: 166px;
    border-radius: 15px;
  }

  .dropdown-Avg-img {
    margin-left: -41px;
    margin-top: 45px;
  }

  .dropdown-Inc-img {
    margin-left: -51px;
    margin-top: 32px;
    max-width: 147px;
  }

  .dropdown-profit-img {
    margin-left: -25px;
    margin-top: 45px;
  }

  .dropdown-doctor-img {
    /* 	width: 109px;
		margin-left: -142px;
		margin-top: 24px; */
    width: 109px;
    margin-left: -4px;
    margin-top: 30px;
  }

  #nav.card-text {
    font-size: 14px;
    color: $color_6;
  }

  #nav.card-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: $color_5;
  }
}

@media (min-width: 1401px) and (max-width: 1599px) {
  #nav.card {
    width: 20vw;
    height: 166px;
    border-radius: 15px;
  }
}

@media (min-width: 1601px) and (max-width: 2100px) {
  #nav.card {
    width: 19vw;
    height: 200px;
    border-radius: 15px;
  }

  .img {
    width: 180px;
    height: 115px;
    margin-top: -14px;
    margin-left: 80px;
  }

  #nav.card-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: $color_5;
  }

  .dropdown-Avg-img {
    margin-left: -41px;
    margin-top: 65px;
  }

  .dropdown-profit-img {
    margin-left: -25px;
    margin-top: 65px;
  }

  .dropdown-doctor-img {
    margin-left: -1rem;
    margin-top: 45px;
  }

  .dropdown-Inc-img {
    margin-left: -51px;
    margin-top: 56px;
    max-width: 147px;
  }
}

@media (min-width: 2101px) and (max-width: 3200px) {
  #nav.card {
    width: 19.5vw;
    height: 230px;
    border-radius: 15px;
  }

  .dropdown-Inc-img {
    margin-left: -51px;
    margin-top: 74px;
    max-width: 147px;
  }

  .dropdown-Avg-img {
    margin-left: -31px;
    margin-top: 85px;
  }

  .dropdown-profit-img {
    margin-left: -14px;
    margin-top: 85px;
  }

  .dropdown-doctor-img {
    margin-left: -1rem;
    margin-top: 65px;
  }

  #nav.card-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: $color_5;
  }
}

.heading {
  font-family: $font-family_1;
}

.loanAmount {
  font-size: 30px;
  font-weight: 400;

}

.fw-400 {
  font-weight: 400 !important;
  font-size: 18px;
}

.loanImgTxtcolor {
  color: rgba(166, 166, 166, 1);
  font-size: 18px;
}

@media (min-width: 200px) and (max-width: 578px) {
  
  .heading {
    font-size: 2.3em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_5;
  }

  .heading-min {
    font-size: 1.4em;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_1;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sub-text {
    font-size: 1.4em;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    color: $color_1;
  }

  .content-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
  }

  .sub-title {
    font-size: 2.3em;
    font-weight: 700;
    line-height: 50px;
    color: $color_5;
    margin-top: 36px;
  }

  .btn-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .center-sm {
    text-align: center;
  }
  .center-text {
    text-align: center;
  }
  .rectangle {
    background-color: $background-color_1;
    height: 485px;
    width: 255px;
  }

  .rectangle1 {
    background-color: $background-color_2;
    height: 485px;
    width: 255px;
  }

  .rectangle2 {
    background-color: $background-color_3;
    height: 485px;
    width: 255px;
  }

  .rectangle3 {
    background-color: $background-color_4;
    height: 485px;
    width: 255px;
  }

  .pill {
    border: 3px solid #74c947;
    border-radius: 30px;
    width: 93vw;
    height: fit-content;
  }

  .pill-icon {
    width: 120px;
    height: 120px;
  }

  .pill-text {
    font-size: 1.1em;
    font-weight: 700;
    line-height: 20px;
    padding: 40px 15px 0px 30px;
    margin-top: 50px;
  }

  .pill-innertext {
    font-weight: 400;
    font-size: 1em;
    line-height: 25px;
    padding: 0px 10px 25px 30px;
  }

  .accordion-button {
    padding: 0px;
    font-weight: 500;
    border: 0;
    font-size: 18px;
    color: $color_3 !important;
    text-align: left;
    line-height: 28px;
  }

  .sub-heading {
    font-size: 1.6em;
    font-weight: 400;
    line-height: 40px;
    color: $color_5;
    margin-top: 0px !important;
  }

  li {
    span {
      font-size: 14px;
      line-height: 26px;
      color: $color_1;
    }
  }

  .accordion-body {
    padding: 15px;
    font-size: 14px;
  }

  #loan-Card.card {
    border: 2px solid #e0ecfe;
    border-radius: 12px;
    min-height: fit-content;
  }
}

@media (min-width: 578px) and (max-width: 771px) {
  #loan-Card.card {
    border: 2px solid #e0ecfe;
    border-radius: 12px;
    min-height: fit-content !important;
  }

  .center-text {
    text-align: center;
  }

  .pill {
    border: 3px solid #74c947;
    border-radius: 30px;
    height: fit-content;
  }

  .pill-icon {
    width: 120px;
    height: 120px;
  }

  .pill-text {
    font-size: 1.5em !important;
    font-weight: 700;
    line-height: 20px;
    padding: 40px 15px 0px 30px;
    margin-top: 50px;
    margin-left: 1px !important;
  }

  .pill-innertext {
    font-weight: 400;
    font-size: 1.3em !important;
    line-height: 25px;
    padding: 0px 10px 25px 30px !important;
    margin-left: 1px !important;
  }

  .flex {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 578px) and (max-width: 3020px) {
  .accordion-button {
    padding: 0px;
    font-weight: 500;
    border: 0;
    font-size: 20px !important;
    color: $color_3 !important;
    text-align: left;
  }

  .accordion-body {
    padding: 15px;
    font-size: var(--content-fs) !important;
    line-height: 35px;
  }

  .dropdown-menu {
    border: none !important;
    // background-color: $background-color_7 !important;
    // left: 0px;
    margin-top: -12px;
    // padding: 13px;
  }

  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        background-color: $background-color_5;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        font-size: 14px !important;
        margin: 9px;

        &:hover {
          color: $color_3;
        }
      }
    }
  }

  .navbar {
    .navbar-nav {
      .nav-link {
        &:hover {
          color: $color_3 !important;
        }
      }
    }
  }
}

@media (min-width: 578px) and (max-width: 990px) {
  .dropdown {
    .dropdown-menu {
      .dropdown-item {
        background-color: $background-color_5;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        font-size: 15px !important;

        &:hover {
          color: $color_3;
        }
      }
    }
  }

  .pill-text {
    font-size: 1.9em;
    font-weight: 700;
    line-height: 55px;
    margin-left: 60px;
  }

  .pill-innertext {
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    margin-left: 60px;
  }

  .heading {
    font-size: 2em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_5;
  }

  .heading-min {
    font-size: 1.4em;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_1;
  }

  .sub-heading {
    font-size: 1.6em;
    font-weight: 400;
    line-height: 40px;
    color: $color_5;
    margin-top: 0px !important;
  }

  .sub-text {
    font-size: 1.8em;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    color: $color_1;
  }

  .content-text {
    font-size: var(--content-fs);
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .sub-title {
    font-size: 2.8em;
    font-weight: 700;
    line-height: 60px;
    color: $color_5;
    margin-top: 34px;
  }

  .btn-center {
    text-align: center;
  }

  li {
    span {
      font-size: 18px;
      line-height: 26px;
      color: $color_1;
    }
  }

  .accordion-body {
    padding: 15px;
    font-size: var(--content-fs);
  }

  #loan-Card.card {
    border: 2px solid #e0ecfe;
    border-radius: 12px;
    min-height: 33em;
  }
}

@media (min-width: 991px) and (max-width: 1199px) {
  .heading {
    font-size: 3em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_5;
  }

  .heading-min {
    font-size: 1.4em;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_1;
  }

  .sub-text {
    font-size: 2em;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    color: $color_1;
  }

  .content-text {
    font-size: var(--content-fs);
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .sub-title {
    font-size: 2.8em;
    font-weight: 700;
    line-height: 60px;
    color: $color_5;
  }

  .sub-heading {
    font-size: 1.8em;
    font-weight: 400;
    line-height: 40px;
    color: $color_5;
    margin-top: 85px;
  }

  .pill-text {
    font-size: 2em;
    font-weight: 700;
    line-height: 45px;
    margin-left: 60px;
  }

  .pill-innertext {
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    margin-left: 60px;
  }

  .leftBorder {
    border-left: 1px solid rgba(23, 82, 95, 0.5);
  }

  li {
    span {
      font-size: 18px;
      line-height: 26px;
      color: $color_1;
    }
  }

  .accordion-body {
    padding: 15px;
    font-size: var(--content-fs) !important;
  }

  #loan-Card.card {
    border: 2px solid #e0ecfe;
    border-radius: 12px;
    min-height: 36em;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .RBL-image {
    max-width: 550px !important;
    max-height: 450px !important;
    margin-left: -4vw !important;
    margin-top: -9vh !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .heading {
    font-size: 2.9em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_5;
  }

  .heading-min {
    font-size: 1.4em;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_1;
  }

  .sub-text {
    font-size: 2.2em;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    color: $color_1;
  }

  .content-text {
    font-size: var(--content-fs);
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .sub-title {
    font-size: 3em;
    font-weight: 700;
    line-height: 60px;
    color: $color_5;
  }

  .sub-heading {
    font-size: 2.1em;
    font-weight: 400;
    line-height: 40px;
    color: $color_5;
    margin-top: 85px;
  }

  .ABB-image {
    max-width: 470px;
    max-height: 345px;
    margin-left: -3vw;
    margin-top: 7%;
    overflow: hidden;
  }

  .PBL-image {
    max-width: 455px;
    max-height: 463px;
    margin-left: -3vw;
  }

  .RBL-image {
    max-width: 600px;
    max-height: 450px;
    margin-left: -5vw;
    margin-top: -9vh;
  }

  .OPL-image {
    max-width: 550px;
    max-height: 450px;
    margin-left: -5vw;
    margin-top: -4vh;
  }

  .pill-text {
    font-size: 2em;
    font-weight: 700;
    line-height: 55px;
    margin-left: 60px;
  }

  .pill-innertext {
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    margin-left: 60px;
  }

  .leftBorder {
    border-left: 1px solid rgba(23, 82, 95, 0.5);
  }

  li {
    span {
      font-size: 20px;
      line-height: 26px;
      color: $color_1;
    }
  }

  .accordion-body {
    padding: 15px;
    font-size: var(--content-fs) !important;
  }

  #loan-Card.card {
    border: 2px solid #e0ecfe;
    border-radius: 12px;
    min-height: 33em;
  }
}

@media (min-width: 1200px) and (max-width: 1320px) {
  .heading {
    font-size: 2.6em !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_5;
  }

  .PBL-image {
    max-width: 430px;
    max-height: 463px;
    margin-left: -3vw;
  }
}

@media (min-width: 1400px) and (max-width: 3200px) {
  .heading {
    font-size: 3em;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_5;
  }

  .heading-min {
    font-size: 1.4em;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0em;
    color: $color_1;
  }

  .sub-text {
    font-size: 2.2em;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    color: $color_1;
  }

  .content-text {
    font-size: var(--content-fs);
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  li {
    span {
      font-size: 20px;
      line-height: 26px;
      color: $color_1;
    }
  }

  .sub-title {
    font-size: 3em;
    font-weight: 700;
    line-height: 60px;
    color: $color_5;
  }

  .sub-heading {
    font-size: 2.25em;
    font-weight: 400;
    line-height: 40px;
    color: $color_5;
    margin-top: 85px;
  }

  .leftBorder {
    border-left: 1px solid rgba(23, 82, 95, 0.5);
  }

  .ABB-image {
    max-width: 460px;
    max-height: 463px;
    margin-left: -2vw;
    // margin-top: -9%;
  }

  .PBL-image {
    max-width: 480px;
    max-height: 400px;
    margin-left: -3vw;
    // margin-top: -9vh;
  }

  .RBL-image {
    max-width: 550px;
    max-height: 450px;
    margin-left: -4vw;
    margin-top: -9vh;
  }

  .pill-text {
    font-size: 2em;
    font-weight: 700;
    line-height: 55px;
    margin-left: 60px;
  }

  .pill-innertext {
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    margin-left: 60px;
  }

  #loan-Card.card {
    border: 2px solid #e0ecfe;
    border-radius: 12px;
    height: 450px !important;
  }
}

@media (min-width: 1900px) and (max-width: 3200px) {
  .ABB-image {
    max-width: 460px !important;
    margin-left: -2vw !important;
    margin-top: -12.1% !important;
  }

  .IBL-image {
    max-width: 555px !important;
    margin-left: -1vw !important;
  }

  .PBL-image {
    max-width: 480px !important;
    margin-left: -2vw !important;
    // margin-top: -5.5vh !important;
  }

  .RBL-image {
    max-width: 520px !important;

    margin-left: 2vw !important;
    margin-top: -6vh !important;
  }
}

.border {
  border: 1px solid #c5c2c2;
  box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 30px 0px 30px 0px;
}

.nav-tabs .nav-link.active {
  color: $color_3 !important;
  // border-bottom:2px solid $color_3 !important;
  text-decoration: underline;
  background-color: $background-color_5 !important;
}

.tab-card {
  border: 1px solid #eee;
}

.footer-underline {
  &:hover {
    border-bottom: 1px solid white;
    padding-bottom: 1px;
  }
}

#partnerWithUs.content-text {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}


.custom-list {
  list-style-type: none;
  padding: 0;
}

.custom-list li {
  position: relative;
  padding-left: 23px; 
}

.custom-list li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2.2px;
  transform: translateY(50%);
  width: 15px;
  height: 15.5px;
  background-image: url('../assets/Polygon 1.svg');
  background-repeat: no-repeat;
  background-position: center;
}
