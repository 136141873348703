@import url("http://fonts.cdnfonts.com/css/avantgarde-md-bt");
// @import './variables.scss';

:root {
    --content-fs: 1.2em;
    --heading-fs: 3.4em;
    --subheading-fs: 2.5em;

}

.about {
    font-size: 1rem;
    font-family: 'AvantGarde Md BT', sans-serif;
    font-weight: 500;
    color: #666666;
}



.label {
    font-size: 32px;
    line-height: 35px;
    text-align: center;
    color: #17525F;
}



.leaders-Name {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color:$color_4;
    padding-top: 0.5em;
    text-align: center;
}

.leaders-pos {
    font-weight: 400;
    font-size: 16x;
    line-height: 20px;
    color:$color_4;
    text-align: center;
}


@media (min-width: 200px) and (max-width: 578px) {
    .body-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #666666;
    }

    .margin-top {
        margin-top: 120px;
    }

    .leader-img {
        border-radius: 0px 75px 0px 75px;
    }

    .leader-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #666666;
    }

    .leaders-Name {
        font-weight: 700;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color:$color_4;

    }

    .leaders-pos {
        font-weight: 700;
        font-size: 16px;
        line-height: 25px;
        color: $color_4;
    }

    /*  .margin-top {
        margin-top: 160px;
    }
 */
    /*  .name-box {
        width: 250px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 50px;
        margin-top: 20px;
    }
 */
}

@media (min-width: 300px) and (max-width: 400px) {
    /*  .name-box {
        width: 250px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 12vw;
        margin-top: 20px;
    }
 */

}

@media (min-width: 401px) and (max-width: 578px) {
    /*  .name-box {
        width: 300px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 13vw;
        margin-top: 20px;
    }
 */


}

@media (min-width: 579px) and (max-width: 790px) {
    .name-box {
        width: 300px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 27vw;
        margin-top: 20px;
    }



}

@media (min-width: 791px) and (max-width: 990px) {
    .name-box {
        width: 300px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 28.5vw;
        margin-top: 20px;
    }



}

@media (min-width: 579px) and (max-width: 990px) {
    .body-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #666666;
    }

    .leader-text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #666666;
        padding-left: 20px;
        padding-right: 20px;
    }

    .leaders-Name {
        font-weight: 700;
        font-size: 18px;
        line-height: 36px;
        text-align: center;
        color:$color_4;
    }

    .leaders-pos {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
        color:$color_4;
    
    }

    .px-xxl {
        padding-left: 1em;
        padding-right: 1em;
    }
    #about-Card.card {
             
       background: #FFFFFF;
       border-radius: 20px;
       
       }
       .text-height{
        min-height: 11rem;
       }
    

}

@media (min-width: 900px) and (max-width: 990px) {

    .name-box {
        width: 340px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 28.5vw;
        margin-top: 20px;
    }

}

@media (min-width: 991px) and (max-width: 1199px) {
    .leader-text {
        font-style: normal;
        font-weight: 400;
        font-size: $content;
        line-height: 36px;
        color: #666666;
        padding-left: 20px;
        padding-right: 20px;
    }

    .Leaders-box {
        background: #FFFFFF;
        border: 1px solid #17525F;
        border-radius: 25px;
    }

    .body-text {
        font-weight: 400;
        font-size: $content;
        line-height: 36px;
        color: #666666;
    }

    .name-box {
        width: 320px;
        background: #17525F;
        border-radius: 15px;
        margin-left: 31.5vw;
        margin-top: 20px;
    }
    #about-Card.card {
       /*  min-height: 470px;
        */ 
       background: #FFFFFF;
       border-radius: 20px;
       
       }
       .text-height{
        min-height: 14.3rem;
       }
    

    .px-xxl {
        padding-left: 1em;
        padding-right: 1em;
    }

    /*  .name-box {
        width: 415px;
        background: #17525F;
        border-radius: 0px 0px 75px 0px;
        margin-left: 28vw;
        margin-top: -5px;
        padding: 20px 15px 20px 15px;
    }
    */
}

@media (min-width: 1200px) and (max-width: 3200px) {
    .body-text {
        font-weight: 400;
        font-size: $content;
        line-height: 36px;
        color: #666666;
    }

    .leader-text {
        font-style: normal;
        font-weight: 400;
        font-size: $content;
        line-height: 30px;
        color: #666666;
    }

    .Leaders-box {
        background: #FFFFFF;
        border: 1px solid #17525F;
        border-radius: 25px;
        /*  transform: matrix(1, 0, 0, -1, 0, 0); */
    }

    .name-box {
        width: 180px;
        background: #17525F;
        border-radius: 0px 0px 20px 20px;
    }
    #about-Card.card {
      /*   min-height: 430px;
       */  
       background: #FFFFFF;
       border-radius: 20px;
       
       }
       .text-height{
        min-height: 12.5rem;
       }
    

    /*     .px-xxl {
        padding-left: 2em;
        padding-right:2em;
    }
 */
}
.color{
    &:hover{
        color: #74C974 !important;
        text-decoration: underline  !important;
       
      }
    color: #666666 !important;
    text-decoration: underline  !important;
    

} 

@media (min-width: 1400px) and (max-width: 3200px) {
    .text-height{
        min-height:9.1rem !important
    }
    }
