
$color_1: #17525F;
$color_2: #7B7575;
$color_3: #666666;
$color_4: #fff;
$font-family_1: 'AvantGarde Md BT', sans-serif;
$font-family_2: 'Fira Sans', sans-serif;
$background-color_1: #17525F;
$background-color_2: #F5F5F6;
$background-color_3: #74C947;



.margin-top{
    margin-top: 150px!important;
}
/* 
@media (min-width: 1200px) and (max-width: 1300px) {
    .digital-img {
        position: absolute;
        width: 240px;
        height: 410px;
        right: 308px !important;
        margin-top: -468px !important;
    }
  
    .mobile-innerimg {
      left: 8px;
      top: 152px;
      position: absolute;
      width: 259px;
      height: 470px;
    }
  
    .Loan-img {
      position: absolute;
      top: 162px;
      width: 260px;
      height: 450px;
      left: 9px;
    }
  
    .left-ani-1 {
      position: absolute;
      left: -47%;
      top: 25%;
      width: 360px;
    }
  
    .right-ani-1 {
      position: absolute;
      left: 31%;
      top: 32%;
      width: 320px;
    }
  
    .right-ani-2 {
      position: absolute;
      top: 60.5%;
      left: 42%;
      width: 280px;
    }
  
    .ass-right-ani-1 {
      position: absolute;
      left: 33%;
      top: 64.5%;
      width: 285px;
    }
  
    .ass-left-ani-2 {
      position: absolute;
      left: -40%;
      top: 71%;
      width: 230px;
    }
  
    .dec-left-ani {
      position: absolute;
      left: -42%;
      top: 38%;
      width: 220px;
    }
  
    .dec-right-ani {
      position: absolute;
      left: 112px;
      top: 40%;
      width: 340px;
    }
  
    .loan-left-ani2 {
      position: absolute;
      left: -38%;
      top: 60%;
    }
  
    .loan-right-ani {
      position: absolute;
      left: 52%;
      top: 65.5%;
    }
  
  } 
  */ /* .mobile-innerimg {
        left: 33px;
        top: 82px;
        position: absolute;
        width: 300px;
    } */
  /* Laptop css */
  /*  .image1 {
      position: absolute;
      top: 64%;
      left: 37px;
      max-width: 22%;
  
    }
  
    .image2 {
      position: absolute;
      top: 78%;
      left: 34px;
      max-width: 22%;
  
    }
  
    .image3 {
      position: absolute;
      top: 66%;
      right: 40px;
      max-width: 22%;
  
    }
  
    .image4 {
      position: absolute;
      top: 78%;
      right: 46px;
      max-width: 22%;
  
    }
   */
  /* .mobile-innerimg {
        left: 33px;
        top: 82px;
        position: absolute;
        width: 300px;
    } */
  body {
  font-size: 16px;
  font-family: $font-family_1;
  font-weight: 500;
  }
  #navbarDropdown {
  line-height: 30px !important;
  }
  .autoTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: $color_1;
  text-align: center;
  padding-bottom: 26px;
  font-family: $font-family_1;
  }
  .firstAutoWrapper {
  margin-top: 63px;
  margin-bottom: 63px;
  }
  .autoTitleText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: $color_2;
  font-family: $font-family_1;
  margin-top: 15px;
  }
  .unorderedList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  >li {
  padding-bottom: 25px;
  text-indent: -3.5em;
  padding-left: 3.5em;
  >img {
  padding-right: 5px;
  }
  }
  }
  .autoBodyText {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  margin-left: 10px;
  color: $color_2;
  line-height: 33px;
  font-family: $font-family_1;
  }
  .secondAutoTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  color: $color_1;
  font-family: $font-family_1;
  }
  .tech-bg {
  background-color: $background-color_1;
  }
  .changeBodyBackground {
  background-color: $background-color_2;
  }
  .resizeRibbonImage {
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  margin-bottom: 55px;
  }
  .endDivPadding {
  margin-bottom: 34px;
  }
  .underWritingText {
  font-family: $font-family_1;
  margin-top: 57px;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: $color_2;
  }
  .text-color {
  color: $color_2 !important;
  font-family: $font-family_1 !important;
  line-height: 27px !important;
  }
  .section_heading_1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $color_1;
  margin-top: 25px;
  }
  .content-group {
  display: flex;
  box-sizing: content-box;
  } 
  .points-arrow {
  height: 20px;
  width: 20px;
  }
  .section_heading_group {
  display: flex;
  }
  .cutouts1 {
  position: absolute;
  right: 0px;
  }
  .cutouts2 {
  position: absolute;
  left: -160px;
  }
  .mob-ani-2 {
  position: absolute;
  top: 9%;
  left: 4%;
  }
  #leftToRight1 {
  position: absolute;
  animation-duration: 2s;
  animation-timing-function: linear;
  }
  #leftToRight2 {
  position: absolute;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 4s;
  }
  #rightToLeft1 {
  position: absolute;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 2s;
  }
  #rightToLeft2 {
  position: absolute;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 6s;
  }
  .maincolumns {
  margin-top: 125px;
  }
  .mainDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  }
  .titleText {
  text-align: center;
  font-family: $font-family_2;
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  text-transform: capitalize;
  color: $color_1;
  line-height: 65px;
  }
  .subText {
  text-align: center;
  font-family: $font-family_2;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-transform: capitalize;
  color: $color_2;
  padding-bottom: 20px;
  }
  .absImageWrapper {
  display: flex;
  flex-direction: column;
  }
  @media (min-width: 578px) and (max-width: 3200px) {
  .section_heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 40px !important;
  color: $color_1;
  }
  }
  @media (min-width: 200px) and (max-width: 578px) {
  .section_heading {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 40px !important ;
  color: $color_1;
  }
  .showcase {
  max-width: 99%;
  height: auto;
  }
  .fixedImage {
  align-self: center;
  position: absolute;
  top: 74%;
  max-width: 40%;
  }
  }
  @media (min-width: 990px) and (max-width: 1199px) {
    .paddingTop{
      padding-top: 50px;
    }
 
  .digital-img {
  position: absolute;
  width: 240px;
  height: 410px;
  top: 110px;
  left: 34px;
  }
  .left-ani-1 {
  position: absolute;
  left: -53%;
  top: 19%;
  width: 360px;
  }
  .left-ani-2 {
  position: absolute;
  left: -31%;
  top: 44%;
  width: 270px;
  }
  .right-ani-1 {
  position: absolute;
  left: 45%;
  top: 29%;
  width: 320px;
  }
  .right-ani-2 {
  position: absolute;
  top: 66%;
  left: 58%;
  width: 280px;
  }
  .mobile-innerimg {
  position: absolute;
  left: 25px;
  top: 91px;
  width: 259px;
  }
  .ass-left-ani-1 {
  position: absolute;
  left: -43%;
  top: 20%;
  width: 220px;
  }
  .ass-left-ani-2 {
  position: absolute;
  left: -46%;
  top: 75%;
  width: 230px;
  }
  .ass-right-ani-1 {
  position: absolute;
  left: 45%;
  top: 69%;
  width: 285px;
  }
  .dec-left-ani {
  position: absolute;
  left: -49%;
  top: 62%;
  width: 220px;
  }
  .dec-right-ani {
  position: absolute;
  left: 122px;
  top: 54%;
  width: 340px;
  }
  .Loan-img {
  position: absolute;
  top: 100px;
  width: 260px;
  height: 450px;
  left: 12px;
  }
  .loan-left-ani1 {
  position: absolute;
  left: -45%;
  top: 10%;
  }
  .loan-left-ani2 {
  position: absolute;
  left: -46%;
  top: 68%;
  }
  .loan-right-ani {
  position: absolute;
  left: 64%;
  top: 73.5%;
  }
  }
  @media (min-width: 1200px) and (max-width: 3200px) {
    .paddingTop{
      padding-top: 92px;
    }
  .autoresizeImg {
  max-width: 100%;
  max-height: 100%;
  width: 552px;
  height: 500px;
  }  
  .digital-img{
    
    position: absolute;
    width: 225px;
    height: 433px;
    top: 146px;
    left: 40px;
      }
      .Loan-img{
        position: absolute;
        top: 140px;
        width: 260px;
        height: 450px;
        left: 16px;
      }
    
      .left-ani-1 {
        position: absolute;
        left: -45%;
        top: 24%;
        width: 360px;
      }
    
      .left-ani-2 {
        position: absolute;
        left: -26%;
        top: 44%;
        width: 270px;
      }
    
      .right-ani-1 {
        position: absolute;
        left: 36%;
        top: 33%;
        width: 320px;
      }
    
      .right-ani-2 {
    
        position: absolute;
        top: 70%;
        left: 45%;
        width: 280px;
      }
    
    
  .mobile-innerimg {
  left: 23px;
  top: 92px;
  position: absolute;
  width: 259px;
  height: 470px;
  }
  .ass-left-ani-1 {
  position: absolute;
  left: -40%;
  top: 20%;
  width: 220px;
  }
  .ass-left-ani-2 {
  position: absolute;
  left: -38%;
  top: 79%;
  width: 230px;
  }
  .ass-right-ani-1 {
  position: absolute;
  left: 33%;
  top: 72%;
  width: 285px;
  }
  .dec-left-ani {
  position: absolute;
  left: -40%;
  top: 63%;
  width: 220px;
  }
  .dec-right-ani {
  position: absolute;
  left: 140px;
  top: 58%;
  width: 340px;
  }
  .loan-left-ani1 {
  position: absolute;
  left: -40%;
  top: 11%;
  }
  .loan-left-ani2 {
  position: absolute;
  left: -37.5%;
  top: 69%;
  }
  .loan-right-ani {
  position: absolute;
  left: 55%;
  top: 73.5%;
  }
  }
  @media (min-width: 200px) and (max-width: 990px) {
  .md-center {
  text-align: center;
  }
  }
  @media (min-width: 400px) and (max-width: 578px) {
  .showcase {
  max-width: 99%;
  height: auto;
  }
  .fixedImage {
  align-self: center;
  position: absolute;
  top: 68.5%;
  max-width: 40%;
  }
  .image1 {
  position: absolute;
  top: 55%;
  left: 40px;
  max-width: 22%;
  }
  .image2 {
  position: absolute;
  top: 72%;
  left: 37px;
  max-width: 22%;
  }
  .image3 {
  position: absolute;
  top: 56%;
  right: 40px;
  max-width: 22%;
  }
  .image4 {
  position: absolute;
  top: 72%;
  right: 46px;
  max-width: 22%;
  }
  }
  @media (min-width: 579px) and (max-width: 990px) {
  .showcase {
  max-width: 100%;
  height: auto;
  }
  .fixedImage {
  align-self: center;
  position: absolute;
  top: 37.4%;
  max-width: 44%;
  }
  .image1 {
  position: absolute;
  top: 59%;
  left: 66px;
  max-width: 22%;
  }
  .image2 {
  position: absolute;
  top: 76%;
  left: 73px;
  max-width: 22%;
  }
  .image3 {
  position: absolute;
  top: 61%;
  right: 75px;
  max-width: 22%;
  }
  .image4 {
  position: absolute;
  top: 76%;
  right: 81px;
  max-width: 22%;
  }
  }
  @media (min-width: 991px) and (max-width: 1199px) {
  .showcase {
  max-width: 97%;
  height: auto;
  }
  .fixedImage {
  align-self: center;
  position: absolute;
  top: 27.4%;
  height: 444px;
  }
  .image1 {
    position: absolute;
    top: 54%;
    left: 79px;
    max-width: 22%;
  }

  .image2 {
    position: absolute;
    top: 73%;
    left: 91px;
    max-width: 22%;
  }

  .image3 {
    position: absolute;
    top: 56%;
    right: 123px;
    max-width: 23%;

  }

  .image4 {
    position: absolute;
    top: 73%;
    right: 137px;
    max-width: 22%;

  }
 }
  @media (min-width: 1200px) and (max-width: 3200px) {
  .showcase {
  max-width: 97%;
  height: auto;
  }
  .fixedImage {
  align-self: center;
  position: absolute;
  top: 27.4%;
  height: 500px;
  }
  .image1 {
    position: absolute;
    top: 50%;
    left: 58px
  }

  .image2 {
    position: absolute;
    top: 70%;
    left: 15px;
  }

  .image3 {
    position: absolute;
    top: 52%;
    right: 60px;
  }

  .image4 {
    position: absolute;
    top: 70%;
    right: 82px;
  }
/* 

  .image1 {
  position: absolute;
  top: 89%;
  left: 160px;
  }
  .image2 {
  position: absolute;
  top: 122%;
  left: 84px;
  }
  .image3 {
  position: absolute;
  top: 91%;
  right: 185px;
  }
  .image4 {
  position: absolute;
  top: 122%;
  right: 182px;
  }
 */  }
  @media (min-width: 1400px) and (max-width: 3200px) {
    .paddingTop{
      padding-top: 92px;
    }
 
  .showcase {
  max-width: 100%;
  height: auto;
  }
  .fixedImage {
  align-self: center;
  position: absolute;
  top: 27.4%;
  height: 500px;
  }
   .image1 {
  position: absolute;
  top: 45%;
  left: 95px;
  }
  .image2 {
  position: absolute;
  top: 67%;
  left: 44px;
  }
  .image3 {
  position: absolute;
  top: 47%;
  right: 150px;
  }
  .image4 {
  position: absolute;
  top: 67%;
  right: 175px;
  }
  .left-ani-1 {
  position: absolute;
  left: -39%;
  top: 24%;
  width: 360px;
  }
  .left-ani-2 {
  position: absolute;
  left: -26%;
  top: 44%;
  width: 270px;
  }
  .right-ani-1 {
  position: absolute;
  left: 28%;
  top: 33%;
  width: 320px;
  }
  .right-ani-2 {
  position: absolute;
  left: 39%;
  width: 280px;
  }
  .mobile-innerimg {
  left: 23px;
  top: 92px;
  position: absolute;
  width: 259px;
  height: 470px;
  }
  .ass-left-ani-1 {
  position: absolute;
  left: -40%;
  width: 220px;
  }
  .ass-left-ani-2 {
  position: absolute;
  left: -33%;
  width: 230px;
  }
  .ass-right-ani-1 {
  position: absolute;
  left: 37%;
  width: 285px;
  }
  .dec-left-ani {
  position: absolute;
  left: -34%;
  width: 220px;
  }
  .dec-right-ani {
  position: absolute;
  left: 142px;
  width: 340px;
  }
  .loan-left-ani1 {
  position: absolute;
  left: -40%;
  top: 11%;
  }
  .loan-left-ani2 {
  position: absolute;
  left: -31%;
  top: 67%;
  }
  .loan-right-ani {
  position: absolute;
  left: 46%;
  top: 73.5%;
  }
  }
  