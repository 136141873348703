#otp {
    letter-spacing: 28px;
    padding-left: 12px;
    margin-right: -34px;
    border: none;
    background-image: linear-gradient(to left, #333 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 320px;
    min-width: 289px;
    font-size: 2.5rem
}

#otp:focus {
    border: none;
    outline: none;
}
#inner-div {
     left: 0;
     position: sticky;
}

#outer-div {
    text-align: center;
     width: 200px;
     overflow: hidden !important;
} 

.otp-textbox.w-input {
    background: none;
    border: none;
}

.otp-textbox-3 {
height: 48px;
margin-right: 3px;
margin-left: 3px;
padding-top: 8px;
padding-left: 12px;
border-bottom: 1px solid #bbb;
border-radius: 0px;
font-size: 30px;
letter-spacing: 2.3rem;
}
.otp-container{
    max-width:255px !important;
}
.resent-otp-link {
    cursor: pointer;
} 
@media (min-width: 578px) and (max-width: 795px) {
    .form{
        width:90%
    }
}

