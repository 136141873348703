$primary-color: '#74c947';
$secorday-color: '#ffffff';
$text-color: '#666666';
$link-hover-color: '#808080';
$sub-text-color: '#17525F';
$color_1: #333333;
$color_2: #74C947;
$color_3: #444444;
$color_4: #17525F;
$color_5: #666666;
$color_6: #74c937;
$color_7: #FFFFFF;
$color_8: white;
$color_9: rgba(255, 255, 255, 0.6);
$color_10: #999999;

$color_11: inherit;
$color_12: #74c974;


$background-color_1: #74C947;
$background-color_2: white;
$background-color_3: transparent;
$background-color_4: #6FB07F;
$background-color_5: #17525F;
$background-color_6: #EDEDED80;
$background-color_7: #FFFFFF;
$background-color_8: #003953;

$background-color_9: #877551;
$background-color_10: #4C835B;
$background-color_11: #6778B3;
$background-color_12: #3cfa75;
$background-color_13: #e7e3e3;
$background-color_14: #74c937;