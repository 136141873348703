@import url("http://fonts.cdnfonts.com/css/avantgarde-md-bt");
body {
  font-size: 1rem;
  font-family: $font-family_1 !important;
  font-weight: 500;
  color: $color_1;
}

@media (min-width: 768px) and (max-width: 3200px) {
.subtitle{
    color: #011E3F; ;
    font-size: 32px;
    font-weight: bold; 
    text-align: center;
    margin-top: 20px; 
  }
}

@media (min-width: 360px) and (max-width: 768px) {
  .subtitle{
      color: #011E3F; ;
      font-size: 24px;
      font-weight: bold; 
      text-align: center;
      margin-top: 10px; 
    }
  }

  .img-height{
    height: 208px;
    min-height: 100%;
    height: auto;
    width: auto;
  }
  
  .date-text{
    color: #999999; 
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    
  }
  
  .card-title{
    color: #363636;
    font-weight: 600;
    font-size: 18px; 
  }
  
  .card-desc{
    color: #363636;
    font-weight: 400;
    font-size: 14px;
  }
  @media (min-width: 768px) and (max-width: 3200px) {
  .card-image{
    min-height: 215px;
  }
  @media (min-width: 768px) and (max-width: 3200px) {
  }
  .cardlayout-image{
    min-height: 267px;
  }
}
@media (min-width: 768px) and (max-width: 3200px) {
  .banner-img{
    width:550px !important;
    height: 370px !important;
  }
}

  .line-clamp {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    position: relative;
  }
  
  // .line-clamp::after {
  //   content: "...";
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   padding-left: 5px;
  //   background: linear-gradient(to right, transparent, white 70%);
  // }
  
  .shadow{
    min-height: 100%;
  }
  
  .shadow:hover{
  
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    
  }

  .card-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .bg-Header{
    // background-image: url('/assets/HeaderSection.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  .bg{
    .bg {
      /* The image used */
      background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).webp");
    
      /* Full height */
      height: 100%;
    
      /* Center and scale the image nicely */
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }


.card-content{
    padding-bottom: 56.5%;
  }

  .desc-text{
    font-size: 1.005rem;
    font-weight: 400;
    line-height: 24px;
   
  }

  .title-text{
    font-size: 2.25rem;
    line-height: 48px;
    font-weight: bold;
    margin-bottom: 1.35rem;
    color: #011E3F;
  }

  .aspect-w-16 {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
  }
  
  .aspect-w-16 > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .related-text{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 20px;
    color: #363636;
  }

  .related-title{
    font-size: 1.25rem;
    line-height: 1.7rem;
    font-weight: bold;
    color: #666;
    margin-bottom: -1rem !important;

  }

  .related-title:hover{
    
    color: #011E3F;

  }

  .custom-list {
    list-style-type: none;
    text-decoration-line: none;
   
    
  }

  .custom-list-li:hover{
   color: #011E3F !important;
  //  border: 2px solid #74c947 !important;
  }
  
  .custom-list-li{
    font-size: 1.25rem;
    font-weight: 500;
    color: #4b5563;
  }

  .tag-text{
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #363636;


  }

  .subtitle-text{

    font-size: 1.5rem;
    line-height: 2.7rem;
    font-weight: 600;
    color: #666;
  }

  .subtitle-desc{
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #666;
  }

  .inner-content::-webkit-scrollbar {
    width: 2px;
    height:2px;
    margin-left: 5px;
    padding-left: 5px;
    
  }  
  .inner-content::-webkit-scrollbar-track {
    background: white;
    border-radius: 2px;
    
  }
  .inner-content::-webkit-scrollbar-thumb {
    background-color: gainsboro;
    border-radius: 4px;   
    border: 2px solid gainsboro;  
     
  }

  .home-text{
    color: #4b5563;
    font-size: 1rem;
    font-weight: 600;
  }

  .home-text:hover{
    border-bottom: 2px solid #74c947;
    color: #4b5563;
  }

.event-text{
  color: #4b5563;
  font-size: 1rem;
  font-weight: 600;
  }

  .banner-text{
    font-size: 1.5rem;
    font-weight: bold;
    line-height: normal;
    color: #000000;
  }

  .blog-title{
    font-size: 4rem;
    font-weight: 700;
    line-height: normal;
    font-style: normal;
    color: #17525F;
  }

  .heading-text{
    color: #4b5563;
    font-size: 1rem;
    font-weight: 600;
  }

  .heading-text:hover{
    color: #4b5563;
    font-size: 1rem;
    font-weight: 600;
  }
  .zoom-card:hover{
  transition: transform 0.5s ease;
  }
  .zoom-card:hover{
    transform: scale(1.02); 
  }

  .page {
    outline: none;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    width: fit-content;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    color: #bdbdbd;
    background-color: #ffffff;
    border: 1px solid #bdbdbd;
    border-radius: 0.375rem;
  }
  
  .page:hover {
    background-color: #f3f3f3;
  }
  
  .custom-container {
    justify-content: flex-end;
    display: flex;
    align-items: flex-end;
    gap: 2px;
    list-style-type: none; /* Removes the bullet */
  text-decoration: none; 
}

.custom-button {
  outline: none;
  padding-top: .5rem;
  padding-bottom: .5rem;
  height: fit-content;
  padding-left: .75rem;
  padding-right: .75rem;
  color: #ccc;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  
}


  
.custom-element {
  height: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #ccc;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}


.active-element {
  background-color: #74c947;
  height: fit-content;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: white;
  border: none;
  outline: none;
}

.active-element:active {
  background-color: #74c947;
}

.bg-banner{
  background-color: #D5E5FB;
}
.blogBannerText{
  font-size: 24px !important;
}

.banner-top{
  margin-top: 144px !important;
}
@media (min-width: 768px) and (max-width: 3200px) {
.space-top{
  margin-top: 140px !important;
}
}

@media (min-width: 360px) and (max-width: 768px) {
  .space-top{
    margin-top: 104px !important;
  }
  .blogTitle{
    font-size: 44px !important;
  }
  }

  .whitespace-nowrap{
    white-space: nowrap;
  }