@import url("http://fonts.cdnfonts.com/css/avantgarde-md-bt");
@font-face {
  font-family: 'AvantGarde Md BT';
  font-style: normal;
  font-weight: 400;
  src: local('AvantGarde Md BT'), url('../assets/fonts/AVGARDM_2.woff') format('woff');
}

:root {
    --content-fs: 1.2em;
    --heading-fs: 3.4em;
    --subheading-fs: 2.5em;

}

.form-container {
    display: block;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-font-styles
  {
    font-size: 1rem;
    font-family: 'AvantGarde Md BT', sans-serif;
    font-weight: 500;
    color: #666666;
  }
  
  @media only screen and (min-width:768px) {
    .form-wrapper {
        display: block;
        width: 100%;
        padding:0px 100px 0px 100px;
      }
  }
  @media only screen and (max-width:767px) {
    .form-wrapper {
        display: block;
        width: 100%;
        padding:0px 40px 0px 40px;
      }
  }
  .form-section-heading {
    margin-top: 5rem;
    color: #17525F;
   }
.error-message
{
    color:red;
}
.succes-message {
  height: 50px;
  background-color: #d9f3cc;
}
.success-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
   font-size: 16px;
  text-align: center;
}
.primary-button {
    width: 220px;
    background-color: #74C947;
    height: 48px;
    font-weight: 700;
    border: none;
    border-radius: 30px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
}
.font-colors
{
    color:#74C947 !important;
}

.field-text{
  font-size: 16.2px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;


}

.bank-choose-btn {
  background-color: #fff;
  color: #74c947;
  border-radius: 6px;
  border: 2px solid #74c947;
  padding: 0.5rem;
  font-size: 16px !important;
  content: "BROWSE" !important;
  cursor: pointer;
}

.docs-title {
  color: #74c947;
  font-weight: 500;
  margin-top: 2rem;
}


.docs-self-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  /* -webkit-justify-content: center; */
  /* -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; */
}

.doc-name {
  display: flex;
  justify-content: space-between;
  margin: 30px;
}

.doc-upload {
  margin-left: 100px;
}

.docs-name-x {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.data {
  padding-left: 42%;
}

.showfiles {
  display: flex;
  flex-direction: column;
  min-width: 240px;
}

.shows {
  width: 100px;
}

.upload-doc-name {
  width: 240px;
}

.inputLink {
  display: flex;
  flex-direction: row;
}

.applicantUploadBtn {
  padding-left: 33%;
}

.fa-trash:hover {
  cursor: pointer;
}
.docs-btn {
  width: 50%;
}

.docs-name {
  width: 100%;
}
 

.docs-btn-upload {
  border: 2px solid #74c947;
  border-radius: 6px;
  background-color: #fff;
   color: #74c947;
  font-size: 16px;
  font-weight: 400;
}

.k-upload-selected {
  background-color: #74c947;
  border-radius: 30px;
  width: 100px;
  color: #fff;
   line-height: 30px;
   border: none !important;
   margin-left: 9px;
}
.previous-btn-link {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  color: #74c947;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

::-webkit-file-upload-button {
  background-color: #fff;
  color: #74c947;
  border-radius: 6px;
  border: 2px solid #74c947;
  padding: 0.5rem;
  font-size: 16px !important;
  content: "BROWSE" !important;
  cursor: pointer;
}

#img {
  color: rgba(0, 0, 0, 0);
}
.padding{
  padding-left: 2rem !important;
}
/* 
@media only screen and (min-width:568px) {
.padding{
  padding-left: 2rem !important;
}
}
 */.form-check-input:checked{
   background-color: #74c947 !important;
   border-color: #74c947 !important ;
 }

.defaultDateFormat {
  color: #c7c1c1 !important;
}