@font-face {
    font-family: 'AvantGarde Md BT';
    font-style: normal;
    font-weight: 400;
    src: local('AvantGarde Md BT'), url('../assets/fonts/AVGARDM_2.woff') format('woff');
}
@import './color.scss';
@import './title.scss';
@import './fonts.scss';

.sub-title {
    font-family: $font-family_1;
    font-size: $title;
}

:root {
    --content-fs: 1.2em;
    --heading-fs: 3.4em;
    --subheading-fs: 2.5em;
}

.home_margin-top {
    margin-top: 115px !important;
}

.fontStyle {
    font-size: 16px;
    font-family: $font-family_1;
    font-weight: 300;
}

.brand-img {
    width: 102px;
    height: 20px
}

.no-scroll {
    overflow: hidden !important;
    position: fixed !important;
}

.fixed-position {
    position: fixed !important;
    overflow-y: hidden !important;

}

.border-btm {
    border-bottom: 1px solid rgba(23, 82, 95, 0.5);
}

#navbarDropdown {
    line-height: 30px !important;
}

.navbar {
    top: -10%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.6s ease-in;
}
.active__menu {
    top: 0%;
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
}

.fontSize {
    font-size: 13px;
}


.navbar .navbar-nav .nav-item .nav-link {
    font-size: 1rem;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    color: $color_1;
    ;
}

.navbar .navbar-nav .nav-item .nav-link :hover {
    color: $color_3;
}


.nav-btn {
    display: flex !important;
    background-color: $background-color_1;
    width: 220px;
    height: 48px;
    font-weight: 700;
    border: none;
    border-radius: 30px;
    text-decoration: none !important;
    justify-content: center;
    align-items: center;
}

.secondary-btn {
    &:hover {
		text-decoration: none !important;
        color: $color_2 !important;
	}
    display: flex;
    background-color: $color_8 !important;
    width: 220px;
    height: 48px;
    font-weight: 700;
    border: 2px solid $background-color_1 ;
    border-radius: 30px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    color: $color_2
}

.banner-top {
    margin-top: 100px;
}

.RBI-text {
    font-size: 0.75em;
    color: $color_3;
    font-style: italic;
}

.RBI-img {
    width: 25px;
    height: 25px;
}

@media (min-width: 280px) and (max-width: 578px) {

    .margin-center {
        margin-top: 30px;
        margin-left: 40vw;
    }

    .center {
        margin-top: 30px;
        margin-left: 18vw;
        margin-bottom: 50px;
    }

    .title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        color: $color_4;
        text-align: center;
    }

    .Quick-title {
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 48px;
        color: $color_4;
        margin-top: 60px;
    }

    .text {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        margin-top: 60px;
        color: $color_1;
    }

    .innerText {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: $color_5;
    }

    .mission-text {
        color: $color_5;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }

    .fin-text {
        font-weight: 700;
        font-size: 29px;
        color: $color_2;
        margin-top: 70px;
        line-height: 45px;
    }

    .current-text {
        font-weight: 700;
        font-size: 28px;
        color: $color_4;
        margin-top: 70px;
        line-height: 45px;
    }

    .sm-centre {
        font-size: 26px;
        font-weight: 500;
        line-height: 36px;
        text-align: center;
        margin-top: 40px;
    }

    .box {
        background: $background-color_5;
        margin-top: 30px;
    }

    .cardOutline {
        height: 360px !important;
        border-radius: 0px !important;
    }
} 
@media (min-width: 200px) and (max-width: 990px) {
    .height {
        height: 100vh !important;
    }

    .top {
        position: fixed !important;
    }
    .nav-sm {
        margin-left:2.3vw;
        margin-top: 13px;
    }
    
 .navbar-nav .dropdown-menu {
    background-color: #f8f9fa !important;
}

    .visuallyHidden {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
    }

    .navmenu{
        border:none;
        color:$color_6;
        background-color: $background-color_3;
        margin-right: 5px;
    }

    .navbar-toggle.collapsed span.icon-bar:nth-child(2) {
        -webkit-transform: translateY(0) rotate(0);
                transform: translateY(0) rotate(0);
      }
      .navbar-toggle.collapsed span.icon-bar:nth-child(3) {
        opacity: 1;
      }
      .navbar-toggle.collapsed span.icon-bar:nth-child(4) {
        -webkit-transform: translateY(0) rotate(0);
                transform: translateY(0) rotate(0);
      }
      .navbar-toggle span.icon-bar {
        -webkit-transition: all 300ms;
        transition: all 300ms;
      }
      .navbar-toggle span.icon-bar:nth-child(2) {
        -webkit-transform: translateY(300%) rotate(45deg);
                transform: translateY(300%) rotate(45deg);
      }
      .navbar-toggle span.icon-bar:nth-child(3) {
        opacity: 0;
      }
      .navbar-toggle span.icon-bar:nth-child(4) {
        -webkit-transform: translateY(-300%) rotate(-45deg);
                transform: translateY(-300%) rotate(-45deg);
      }
    

    .hamburger {
        /*  margin: 0 auto;
       */
        margin-top: 20px;
        width: 30px;
        height: 30px;
        position: relative;
        margin-right: 12px;
    }

    .hamburger .bar {
        padding: 0;
        width: 30px;
        height: 3px;
        background-color: $background-color_1;
        display: block;
        border-radius: 4px;
        transition: all 0.4s ease-in-out;
        position: absolute;
    }

    .bar1 {
        top: 0;
    }

    .bar2,
    .bar3 {
        top: 8.2px;
    }

    .bar3 {
        right: 0;
    }

    .bar4 {
        bottom: 0;
        top: 16.5px
    }

    .checkbox2:checked+label>.hamburger2>.bar1 {
        transform: translateX(40px);
        background-color: $background-color_3;
    }

    .checkbox2:checked+label>.hamburger2>.bar2 {
        transform: rotate(45deg);
    }

    .checkbox2:checked+label>.hamburger2>.bar3 {
        transform: rotate(-45deg);
    }

    .checkbox2:checked+label>.hamburger2>.bar4 {
        transform: translateX(-40px);
        background-color: $background-color_3;
    }

    .dropdown-menu {
        background-color: $background-color_3;
        border: none !important;
    }

    .brand-img {
        width: 123px;
        height: 24px
    }
}
@media screen and (min-width: 578px) {
    .policy-top{
        margin-top: 2.3rem!important;
    }
    /*  .dropdown:hover .dropdown-menu {
        display: block;
    }
    .dropdown .dropdown-menu:hover .dropdown-menu {
        display: block;
    }
    .dropdown .dropdown-menu {
        display: none;
    }
    */
    .top {
        top: -100px !important
    }

    .sm-centre {
        font-size: 22px;
        font-weight: 500px;
        line-height: 36px;
        margin-top: 60px;
    }

    .finplex-label {
        margin-top: 120px;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: $color_6;
    }

    #navbarMargin {
        margin-top: -3px;
    }
}

@media (min-width:250px) and (max-width:299px) {
    .finplex-label {
        margin-top: 120px;
        font-weight: 700;
        font-size: 25px;
        line-height: 45px;
        color: $color_6;
    }
}

@media (min-width:300px) and (max-width:330px) {
    .finplex-label {
        margin-top: 120px;
        font-weight: 700;
        font-size: 27px;
        line-height: 45px;
        color: $color_6;
    }
}

@media (min-width:331px) and (max-width:410px) {
    .finplex-label {
        margin-top: 120px;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: $color_6;
    }
    .min-hgt{
        min-height: 300px !important;
    }
}
@media (min-width:410px) and (max-width:500px) {
    .finplex-label {
        margin-top: 120px;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: $color_6;
    }
    .min-hgt{
        min-height: 370px !important;
    }
}
@media (min-width:500px) and (max-width:578px) {
    .finplex-label {
        margin-top: 120px;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: $color_6;
    }
    .min-hgt{
        min-height: 430px !important;
    }
}



@media (min-width: 579px) and (max-width: 1920px) {
    .title {
        font-weight: 400;
        font-size: 50px;
        font-style: normal;
        color: $color_4;
        line-height: 54px;
    }

    .text {
        margin-top: 36px;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 16px;
        color: $color_1;
    }

    .Quick-title {
        font-size: 50px;
        color: $color_4;
        line-height: 54px;
        margin-top: 100px;
        font-weight: 400;
    }
}

.current-label {
    color: $color_4;
}


/* .number-innerText {
    line-height: 22px;
    font-size: 18px;
    color: $color_5;
} */

@media (min-width: 200px) and (max-width: 578px) {
    .nav-sm {
        margin-left:3.3vw;
        margin-top: 13px;
    }

 
    .vertical-line {
        display: inline-block;
        border-right: 1px solid rgba(153, 153, 153, 0.3);
        height: 100px;
        margin-left: -10px;
        margin-right: 10px;
    }

    .number-txt {
        font-weight: 800;
        font-size: 34px;
        line-height: 36px;
        color: $color_2
    }

    .txt {
        color: $color_5;
        font-weight: 500;
        font-size: 14px;
    }

    .centre {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
    }

    .loans-sm {
        margin-left: 3vw;
    }

    .slider-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 25px;
        letter-spacing: -0.03em;
        color: $color_2
    }

    .slider-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19.6px;
        color: $color_5;
    }

    .slider-inner {
        min-height: 520px;
    }

    .cardAmount {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 31px;
        color: $color_7;
        margin-top: 10px;
    }
    
    .carousel_Manimg{
        max-width: 88%;
    }
    .carousel_Laptop{
        max-width: 100%;
    }
    .sliderleftarrow{
        width: 34px !important;
       height: 34px !important;
       margin-top: -56vw !important;
   }
      
    .sliderrightarrow{
     width: 34px !important;
    height: 34px !important;
    margin-right: -3vw !important;
    margin-top: -56vw !important;
}
    }

    /* .customer-innercarousel {
        min-height: 700px;
    } */

@media (min-width:579px) and (max-width:770px){
    .centre {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
    }}
@media (min-width: 579px) and (max-width: 991px) {
 
    .center {
        width: 120px;
        height: 38px;
    }
    

    .vertical-line {
        display: inline-block;
        border-left: 1px solid rgba(153, 153, 153, 0.3);
        height: 100px;
        margin-right: -40px;
        margin-left: 40px;
    }

    .title {
        font-weight: 400;
        font-size: 46px;
        font-style: normal;
        color: $color_4;
        line-height: 54px;
        text-align: center;
    }

    .text {
        margin-top: 36px;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 10px;
        color: $color_1;
        text-align: center;
    }

    .Quick-title {
        font-size: 46px;
        color: $color_4;
        line-height: 54px;
        margin-top: 100px;
        text-align: center;
        font-weight: 400;
    }

    .md-center {
        text-align: center;
    }

    .innerText {
        color: $color_5;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        padding-left: 50px;
        padding-right: 50px;
    }

    .number-txt {
        font-weight: 800;
        font-size: 40px;
        line-height: 30px;
        color: $color_2
    }

    .txt {
        color: $color_5;
        font-weight: 400;
        font-size: 0.9rem;
    }

    .slider-title {
        font-weight: 500;
        font-size: 32px;
        line-height: 45px;
        /* identical to box height, or 102% */
        letter-spacing: -0.03em;
        color: $color_2
    }

    .slider-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: $color_5;
        margin-bottom: 10px;
    }

    .slider-inner {
        min-height: 540px;
    }

    .box {
        width: 370px;
        height: 316px;
        margin-left: 1vw;
        background: $background-color_5;
        border-radius: 12px;
    }

    /* 
    .cardSmallText {
        font-weight: 500;
        font-size: 17.5px !important;
        line-height: 19px;
        color: $color_7;
    } */

    .cardAmount {
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 31px;
        color: $color_7;
        margin-top: 10px;
    }

    .mission-text {
        color: $color_5;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }

    .customer-slider-img {
        width: 95px !important;
        height: 95px !important;
        margin-top: 100px;
        border-radius: 50%;
        filter: grayscale(100%) !important;
    }
    
    .carousel_Manimg{
        max-width: 60%;
    }
    .carousel_Laptop{
        max-width: 78%;
    }
}

@media (min-width: 578px) and (max-width:780px) {
    .customer-img {
        margin-left: 36.7vw !important;
    }
    .min-hgt{
        min-height:344px !important;
    }
    .cardOutline {
        height: 351px !important;
    }
}
@media (min-width: 578px) and (max-width:690px) {
    .min-hgt{
        min-height:354px !important;
    }
}

@media (min-width: 691px) and (max-width:780px) {
    .min-hgt{
        min-height:410px !important;
    }
}
@media (min-width: 781px) and (max-width:880px) {
    .customer-img {
        margin-left: 35vw !important;
    }
    .min-hgt{
        min-height:460px !important;
     }

}

@media (min-width: 881px) and (max-width:1199px) {
    .customer-img {
        margin-left: 31.5vw !important;
    }
    .min-hgt{
        min-height:580px !important;
     }

}


@media (min-width: 992px) and (max-width: 1199px) {
    .navbar .navbar-nav .nav-item .nav-link {
        font-size: 14px;
    }
    .min-hgt{
        min-height:430px !important;
    }
    .vertical-line {
        display: inline-block;
        border-right: 1px solid rgba(153, 153, 153, 0.3);
        height: 100px;
        margin-left: -40px;
        margin-right: 40px;
    }

    .innerText {
        color: $color_5;
        font-weight: 400;
        font-size: var(--content-fs);
        line-height: 20px;
    }

    .number-txt {
        font-weight: 800;
        font-size: 38px;
        line-height: 30px;
        color: $color_2
    }

    .txt {
        color: $color_5;
        font-weight: 500;
        font-size: 13px;
    }

    .slider-title {
        font-weight: 500;
        font-size: 42px;
        line-height: 45px;
        letter-spacing: -0.03em;
        color: $color_2
    }

    .slider-text {
        font-weight: 400;
        font-size: var(--content-fs);
        line-height: 30px;
        color: $color_5;
    }

    .slider-inner {
        min-height: 490px;
    }

    .margin {
        margin-left: -2vw;
    }

    .box {
        width: 415px;
        height: 336px;
        margin-left: 3.9vw;
        background: $background-color_5;
        border-radius: 12px;
    }

    .cardAmount {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 31px;
        color: $color_7;
        margin-top: 10px;
    }

    .customer-img {
        margin-left: 40vw !important;
    }

    .title {
        font-weight: 400;
        font-size: 50px;
        font-style: normal;
        color: $color_4;
        line-height: 55px;
    }

    .text {
        margin-top: 36px;
        font-style: normal;
        font-weight: 400;
        font-size: 27px;
        line-height: 10px;
        color: $color_1;
    }

    .Quick-title {
        font-size: 50px;
        color: $color_4;
        line-height: 55px;
        margin-top: 100px;
        font-weight: 400;
    }

    .mission-text {
        color: $color_5;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
    }

    .customer-slider-img {
        width: 115px !important;
        height: 115px !important;
        margin-top: 75px;
        border-radius: 50%;
        filter: grayscale(100%) !important;
    }
    
    .carousel_Manimg{
        max-width: 44%;
    }
    .carousel_Laptop{
        max-width: 60%;
    }


}

@media (min-width: 1100px) and (max-width:1199px) {
    .min-hgt{
        min-height:470px !important;
     }

}

@media (min-width: 1200px) and (max-width: 3020px) {

    .leftBorder {
        border-left: 1px solid rgba(23, 82, 95, 0.5);
    }

    .nav-margin {
        margin-left: 70px;
    }

    .vertical-line {
        display: inline-block;
        border-right: 1px solid rgba(153, 153, 153, 0.3);
        /*  margin: 0 10px;
      */
        height: 100px;
        margin-left: -40px;
        margin-right: 55px;
    }

    .number-txt {
        font-weight: 800;
        font-size: 43px;
        line-height: 30px;
        color: $color_2
    }

    .innerText {
        color: $color_5;
        font-weight: 400;
        font-size: var(--content-fs);
        line-height: 20px;
    }

    .mission-text {
        color: $color_5;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
    }

    .txt {
        color: $color_5;
        font-weight: 500;
        font-size: 17px;
    }

    .mr-vl {
        margin-right: 5px;
    }

    .ml-xl {
        margin-left: 30px;
    }

    .title {
        font-weight: 400;
        font-size: 50px;
        font-style: normal;
        color: $color_4;
        line-height: 55px;
    }

    .text {
        margin-top: 36px;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 10px;
        color: $color_1;
    }

    .Quick-title {
        font-size: 50px;
        color: $color_4;
        line-height: 55px;
        margin-top: 100px;
        font-weight: 400;
    }

    .slider-inner {
        min-height: 420px;
    }

    .slider-title {
        font-weight: 500;
        font-size: 42px;
        line-height: 45px;
        /* identical to box height, or 102% */
        letter-spacing: -0.03em;
        color: $color_2;
    }

    .slider-text {
        font-weight: 400;
        font-size: var(--content-fs);
        line-height: 30px;
        color: $color_5;
    }

    .customer-slider-img {
        width: 130px !important;
        height: 130px !important;
        margin-top: 75px;
        border-radius: 50%;
        filter: grayscale(100%) !important;
    }

    .box {
        width: 421px;
        height: 336px;
        margin-left: 4vw;
        background: $background-color_5;
        border-radius: 12px;
    }

    .cardAmount {
        font-weight: 500;
        font-size: 19px;
        line-height: 31px;
        color: $color_7;
        margin-top: 10px;
    }

    .customer-img {
        margin-left: 40vw !important;
    }
    .min-hgt{
        min-height:570px;
    }
    .carousel_Manimg{
        max-width: 44%;
    }
    .carousel_Laptop{
        max-width: 60%;
    }

}

@media (min-width: 1200px) and (max-width: 1300px) {
    .loans-sm {
        margin-left: 0.4vw;
    }

    .customer-img {
        margin-left: 38vw !important;
    }
    .min-hgt{
        min-height:530px !important;
    }
}

@media (min-width: 1301px) and (max-width: 1400px) {
    .loans-sm {
        margin-left: 0.4vw;
    }

    .customer-img {
        margin-left: 37vw !important;
    }
    .min-hgt{
        min-height:550px !important;
    }
}
@media (min-width: 1499px) and (max-width: 1770px) {
    .min-hgt{
        min-height:550px !important;
    }

}
@media (min-width: 1599px) and (max-width: 1900px) {
    .loans-sm {
        margin-left: 1vw;
    }

    .customer-img {
        margin-left: 36vw !important;
    }
    .min-hgt{
        min-height:550px !important;
    }

    .px-xxl {
        padding-left: 7vw !important;
        padding-right: 7vw !important;
    }

    .logo-xxl {
        padding-left: 14vw;
    }

    .xxl {
        padding-right: 14vw;
    }

}

@media (min-width:1900px) and (max-width: 2500px) {

    /*  .loans-sm {
        margin-left: 1.2vw;
    } */
    .customer-img {
        margin-left: 31vw !important;
    }
    .min-hgt{
        min-height:550px !important;
    }

}

@media (min-width:2500px) and (max-width: 3020px) {

    /*  .loans-sm {
        margin-left: 3.6vw;
    } */
    .customer-img {
        margin-left: 23vw !important;
    }
    .min-hgt{
        min-height:550px !important;
    }

}

/* 
.subHeading {
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    color: $color_10;
    margin-bottom: 10px;
}

.minmaxValues {
    margin-top: -17px;
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
    color: $color_10;
}

.cardSmallText {
    font-weight: 500;
    font-size: 20px;
    line-height: 19px;
    color: $color_7;
} */

/* .cardOutline {
    background: $background-color_1;
    border-radius: 12px;
}

.subHeadingValues {
    font-weight: 700;
    font-size: 22px;
    line-height: 18px;
    color: $color_5;
} */

.carousel .carousel-indicators button {
    margin-top: -160px;
    width: 13px;
    height: 13px;
    border-radius: 100%;
    background-color: $background-color_4;
}

.hr {
    border: 1px solid;
    margin-top: 40px;
    color: $color_8;
}

.footer-text {
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
}

.footer-text1 {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
}

.footer-heading {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
}

.footer-text2 {
    line-height: 40px;
    font-size: 16px;
    margin-top: -25px;
    margin-left: 29px;
}

.footer-margin {
    line-height: 40px;
    font-size: 18px;
    margin-top: -25px;
}

.footer-btn {
    background: $background-color_8;
    mix-blend-mode: normal;
    border-radius: 30px;
    width: 220px;
    height: 48px;
    border: none;
}
.marginBottom{
    margin-bottom: 0.3rem;
}

.copyright {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    margin-top: 30px;
    color: $color_9;
    margin-left: px;
}

.bg {
    background-color: $background-color_5;
    margin-top: 60px;
}

.gray-bg {
    background-color: $background-color_6;
}

.txt-bg {
    color: $color_2
}

.footer-txt {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}






@media screen and (min-width: 580px) {
    .brand-img {
        width: 150px;
        height: 40px;
    }

    .bg {
        background-color: $background-color_5;
        margin-top: 140px;
    }
}

/* .slidecontainer {
    width: 100%;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04AA6D;
    cursor: pointer;
} */

.customer-para {
    font-style: normal;
    font-weight: 400;
    font-size: var(--content-fs);
    line-height: 30px;
    text-align: center;
    color: $color_5;
    margin-top: 20px;
    /*  padding: 0px 40px 0px 40px;
 */
}

.customer-Name {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    margin-top: 20px;
    color: $color_4;
}

.customer-pos {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: $color_10;
}

.indicator {
    width: 130px;
    height: 130px;
}

.carousel-indicators .active .img {
    width: 160px;
    height: 160px;
}

.carousel-indicators .active {
    filter: none !important;
}

@media screen and (min-width: 1901px) {
    .px-xxl {
        padding-left: 11vw !important;
        padding-right: 11vw !important;

    }

    .logo-xxl {
        padding-left: 14vw;
    }

    .xxl {
        padding-right: 14vw;
    }
}

.marginTop {
    margin-top: 27px !important;
}

// calculator starts
.rangeslider-horizontal .rangeslider__fill {
    background-color: $background-color_1  !important;
}

.rangeslider-horizontal {
    height: 5px !important;
}

.rangeslider-horizontal .rangeslider__handle {
    background-color: $background-color_1  !important;
    box-shadow: none !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
    width: 22px !important;
    height: 22px !important;
    top: 3px !important;
    left: 3px !important;
    background-color: $background-color_7  !important;
    box-shadow: none !important;
}

.calculatorHeading {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 84px;
    text-align: center;
    color: $color_4;
}

.subHeading {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: $color_10;
    margin-bottom: 10px;
}

.minmaxValues {
    margin-top: -17px;
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: $color_10;
}

.cardAmount {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    color: $color_7;
    margin-top: 10px;
}

.cardSmallText {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 19px;
    color: $color_7;
}

.applyNowLink {
    width: 220px !important;
    height: 48px !important;
    background: $background-color_1;
    border-radius: 30px;
    font-family: $font-family_1;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: $color_7;
}

.cardOutline {
    background: $background-color_5;
    border-radius: 12px;
    height: 320px;
}

.subHeadingValues {
    font-family: $font-family_1;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    /* text-align: center; */
    color: $color_5;
    margin-bottom: 15px;
}
.Rev-Loan{
      font-size:14px !important;
     line-height: 21px;
    margin-top: -0.25rem !important;
 }
.rev:hover .Rev-Loan {
    display:block;
    
}  
.arrow{
    width: 12px !important;
}

ol li{
    color: $color_5 !important;
    margin-top: 15px;
}

