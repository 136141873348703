@import url("http://fonts.cdnfonts.com/css/avantgarde-md-bt");
@import "./color.scss";


.border{
	border: 1px solid #C5C2C2;
	box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.1);
border-radius: 30px;
padding: 30px 0px 30px 0px;
}
.nav-tabs .nav-link{
  font-size: 1.3rem;
	color: $color_1 !important;
 	background-color: $background-color_3 !important;
  padding: 2px 20px;
  font-family: $font-family_1;
}


.nav-tabs .nav-link.active{
	color: $color_2 !important;
  text-decoration: underline !important;
	background-color: $background-color_3 !important;
  font-weight: 600;
  
}
#Calculator .nav-tabs .nav-link.active{
	color: $color_2 !important;
  text-decoration: underline !important;
	background-color: $background-color_3 !important;
}
.tab-card {
	border:1px solid #eee;
  }
  
  .tab-card-header {
	background:none !important;
  }
  /* Default mode */
  .tab-card-header > .nav-tabs {
	border: none;
	margin: 0px;
  }
  .tab-card-header > .nav-tabs > li {
	margin-right: 2px;
  }
  .tab-card-header > .nav-tabs > li > a {
	border: 0;
	margin-right: 0;
	color: #737373;
	padding:2px 20px;
  }
  
  /* .tab-card-header > .nav-tabs > li > a.show {
	  border-bottom:2px solid #007bff;
	  color: #007bff;
  }
   */.tab-card-header > .nav-tabs > li > a:hover {
	  color: $color_2;
  }
  
  .tab-card-header > .tab-content {
	padding-bottom: 0;
  }
 

#States.nav-pills .nav-link{
    &:hover{
        color: #000000  !important;
    }
    color:#000000;
     margin-bottom: 25px !important;
     font-size:20px;
}
#States.nav-pills .nav-link.active{
    &:hover{
        color: white  !important;
    }  
    background-color:$background-color_5 !important;
    color: white  !important;    
   }
   
#cities.nav-pills .nav-link{
    &:hover{
        color: #D9D9D9  !important;
    }
     color:#D9D9D9 !important;
     font-size:20px;
    //  padding:2px 12px 2px 12px !important;
     background: #FFFFFF !important;
    //  border: 2px solid #D9D9D9 !important;
    //  border-radius: 8px !important;
    }
#cities.nav-pills .nav-link.active{
    &:hover{
        color: $color_4  !important;
    }
    color: $color_4  !important;
    // border: 2px solid $color_4 !important;
    font-weight: bold !important;
}
@media screen and (min-width:770px){
.rightBorder{
    border-right:  1px solid $color_5;
}
}
.box-border{
    border:  1px solid $color_5 !important;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
}
#CustomerSupport .tab-card-header{
    border-bottom:  1px solid $color_5 !important;

}
#CustomerSupport p{
    font-size:20px !important;
}
#sub-heading .sub-heading{
    color:$color_2 !important;
}

#blog .secondary-btn{
    padding:5px 15px;
    margin-right:7.9px;
    color:$color_5;
    border:1px solid $color_2;
    width: fit-content;
    height: fit-content;
    margin-bottom: 20px;
    display: inline;
}
@media (min-width:200px) and (max-width:770px){
.tab-responsive{
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
#cities.nav-pills .nav-link{
  margin-bottom: 20px;
}
}
@media (min-width:200px) and (max-width:990px){
   
.content-text1{
  font-size: 1.1em;
  color:$color_1 ;
  font-weight: 700;
}
.content-text2{
  font-size: 1.2em;
  color:$color_5;
  font-weight: 400;
}
.tab-card-header > .nav-tabs > li > a {
  margin-bottom: 20px;
  }
  #Calculator .nav-tabs .nav-link{
    font-size: 20px ;
    color: $color_1 !important;
     background-color: $background-color_3 !important;
    padding: 6px 10px !important;
    font-weight: 500;
  }
  #Calculator .tab-responsive{
    flex-direction: column;
    text-align: start !important;
   }

}
@media (min-width:990px) and (max-width:1199px){
  #Calculator .nav-tabs .nav-link{
    font-size: 18px ;
    color: $color_1 !important;
     background-color: $background-color_3 !important;
    padding: 10px 10px !important;
    font-weight: 500;
  }
  #Calculator.tab-card-header > .nav-tabs > li > a {
    padding:10px 10px !important;
    }
    
 
}
@media (min-width:990px) and (max-width:3200px) 
{
  
.content-text1{
  font-size: 1.2em;
  color:$color_1 ;
  font-weight: 700;
}
.content-text2{
  font-size: 1.5em;
  color:$color_5;
  font-weight: 400;
}
@media (min-width:1200px) and (max-width:3200px){
  #Calculator .nav-tabs .nav-link{
    font-size: 20px;
    color: $color_1 !important;
     background-color: $background-color_3 !important;
    padding: 15px 20px !important;
    font-weight: 600;
  
  }
}
}
.color1{
  color:$color_4;
}
.borderBottom{
  border-bottom: 1px solid #999999;
  padding-bottom: 40px;
}
.form-check-input[type=checkbox] {
   border-radius: 0em !important ; 
}
.form-check {
  padding-left: 2.4em !important;
}
.subcribeBtn{
  height:38px;
  background-color:$background-color_5;
  border:none;
}
.form-check-input[type=checkbox]:focus {
  box-shadow: none !important;
}
#blog-subscribe.form-control{
  border-radius:0rem !important;
  }
#blog-subscribe.form-control:focus {
  box-shadow:none !important; 
}
.fs{
  font-size: 13px !important;
} 
.line-height{
  line-height: 1.6rem !important;
}
